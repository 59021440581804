.add-user {
    border-radius: 6px;
    color: #fff;
    background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x;
    border: none;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
    padding: 8px 14px;
    cursor: pointer;
}

.view_profile {
    width: 500px;
    padding: 32px 24px !important;
}

.MuiDialogContent-root:first-child {
    padding-top: 0;
}

.media {
    gap: 20px;
}

.media-body p {
    font-size: 18px;
    margin-bottom: 0;
}

.media-body div {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 10px;
}

.quciklink-dropdown .dropdown-menu .dropdown-list li {
    width: 97%;
}

.profile-block {
    padding: 0px  !important;
   


}
/* my user image name aand dropdown */
.userInfoMainWrapper{
    /* background-color: yellow; */
    display: flex;
    height: 60px;
}
.userNameWrapper{
    /* background-color: aquamarine; */
  
    width: 210px;
    display: flex;
}
.userNameText{
    color: #000 !important;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}
.DropDownIcon{
    margin-left: 3px;
    width: 17px;
    height: 17px;
    /* background-color: black; */
}
.myprofile-block100{
    padding: 10px  !important;
    background-color:    #b4d3dd  !important;
    border-radius: 8px !important; 
    width: 250px;
    /* max-width: fit-content; */
    height: 50px;
  
}
.userProfileWrapper{
    /* background-color: green; */
    width: 27%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.userEmailWrap{
    display: flex;
    justify-content: center;
    align-items: center;

}

.rct-sidebar .user-profile > img {
    box-shadow: none;
}

.addBtn {
    background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x;
    padding: 6px 38px !important;
}

.addNewCustomerForm .form-control {
    border: 1px solid #3d76a8;
    padding: 10px 12px;
}

.addCustomerModal .modal-content {
    width: 650px;
    padding: 10%;
}

.addNewCustomerForm .form-group {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 25px;
}

.addNewCustomerForm .form-group label {
    margin-bottom: 0;
    width: 30%;
    font-size: 17px;
    color: #000;
}
.project-box{
    padding-bottom: 30px;
}

.projectCardButton{
    padding: 0.2rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.Project-Card-Main{
padding: 20px;
}
.project-card-dataset-text{
    font-size: 1rem;
    word-spacing: 3px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 800;
}
.project-card-dataset-box{
    padding: 1px 4px;
    border: 1px solid #000000;
  
}

.project-heading{
    font-size: 1rem;
    font-weight: 800;
    padding-top: 6px;
    padding-bottom: 6px;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    word-wrap: break-word
}
.heading-container{
    overflow: hidden;
   
}

.project-main-box{
    width: 300px;
    height: 100px;
    border: 1px solid blue;
    box-sizing: border-box;
    
}

.project-date{
 
        font-size: 1rem;
        font-weight: 500;
        word-spacing: 0.1rem;
        font-family: 'IBM Plex Sans', sans-serif;
        overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    word-wrap: break-word
}
.project-card-Status-box{
    padding: 1px 9px;
    margin-left: 4px;
    border: 1px solid #000000;
    background-color: #525b96;
}

.project-card-shadow{
    /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}


.addCustomerModal .modal-title {
    font-size: 18px;
}


.MuiDialog-paperWidthSm {
    width: 800px;
    max-width: 800px !important;
}

.paginationDiv .page-item.active .page-link {
    background-color: #3E4A9E;
    border-color:#525b96 ;
}

.paginationDiv .page-link {
    color: #3E4A9E;
}


.userBlockHorizontalDropdown {
    /* background-color: #0b3d45cf; */
}


/* 
@media (min-width:500px) and (max-width: 1023px){
   
}
@media (min-width:400px) and (max-width: 525px){
    .heading-container{
     width: 50px;
       
    }
}
@media (min-width:200px) and (max-width: 399px){
    .heading-container{
        width: 60px;
          
       }
} */