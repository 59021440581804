@media (max-width: 1366px){
    html {
        font-size: 16px !important;
    }
} 

@media (max-width: 1700px){
    html {
        font-size: 16px !important;
    }
}

.table-all::-webkit-scrollbar {
    display: none !important;
}

.profile_drodown .dropdown-menu{
    will-change: unset !important;
}
.MuiTabs-indicator {
    display: none !important;
}

.MuiTab-root{
    /* border-bottom: 3px solid black !important; */
    max-width: max-content !important;
    min-width: 0 !important;
    
}

.Mui-selected {
    border-bottom: 2px solid #3d4A9E !important;
}

td {
    color: #181D3F !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
    /* background-color: red !important; */

}

.c-primary {
    background-color:#b4d3dd !important;
}

.light-primary {
    background-color:#fff !important;
}

.dark-primary {
    background-color:#b4d3dd  !important;
    color:  white;
}

.Project-cart-main {
    margin-bottom: 9px;
}

.dark-primary-text {
    color: #000 !important;
    font-family: 'IBM Plex Sans', sans-serif !important;

}
/* .button-black{
    background-color: #000 !important;
    color: #fff !important;
} */


.madatory-field {
    margin-left: 7px;
}

.border-box-shadow-image {
    border: 1px solid rgb(211, 202, 202);


}
button{
    font-weight: 500 !important;
}
.MuiButton-label{
    font-weight: 600 !important;
}
.globalFontFamily{
    font-family: 'IBM Plex Sans', sans-serif !important;
}

.text-color-c{
    color:  #000 !important;
}
.text-color-Black{
    color:  #000 !important;
}
.MuiButton-containedPrimary{
    background-color: #3E4A9E !important;
}
.MuiButton-containedPrimary:hover{
    background-color: #252c67  !important;
}

.Comman-Heading{
font-size: 23px;
font-weight: 600;
color :#3E4A9E !important;
}

.ActiveBreadcrum{
color :#3E4A9E !important;

}
.imp-Color{
    color :#3E4A9E !important;
}



/*  Admin Dashboard Table */
.tab-Heading {
    padding-bottom: 0px !important;
    font-size: 15px;
    font-family: 'IBM Plex Sans', sans-serif;
}

.search-Box-Table {
    margin: 30px 0px;
}

/* CSS for user block horizontal font */
.rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu ul li:not(.user-profile-top) a span:not(.badge) {
    /* font-size: 16px !important; */
    font-family: 'IBM Plex Sans', sans-serif;
   
}



/* create Coupan  */
.d-coupan-box {
    justify-content: center;

    font-weight: 800;
}

.d-coupan-heading {
    font-size: 1.5rem;
}


/* create project css */
.box-shadow-box {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.activity_wrap {
    gap: 10px;
}

.btn-info {
    cursor: pointer;
}

.rct-session-wrapper .has-wrapper .has-input {
    border: 1px solid #3d4A9E;
}


.text-theme {
    color:#000 !important;
}

.theme-background {
    background-color: #3d4A9E !important;
}

.error {
    font-size: 16px !important;
    color: red !important;
    margin-top: -15px;
    margin-bottom: 10px;
    text-align: left;
}

.errorText {
    font-size: 16px !important;
    color: red !important;
    text-align: left;
    padding-top: 6px;
    margin-bottom: 0;
}

.edit-form {
    padding: 32px 25px;
    padding-bottom: 5px;
}

.update-btn {
    padding: 7px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

}

.profile-block-wrap {
    background-color: rgb(250, 250, 250) !important;
    width: 220px;
    position: absolute;
    top: 10px;
    /* left: 5px; */
}

.breadcrumb a {
    color:  #fff;
}

.search-row {
    display: flex;
    align-items: center;
    gap: 5px;
}

.search-input {
    border: none !important;
    border-bottom: 1px solid #000;
    width: 260px;
    border: 1px solid black !important;
    padding-left: 10px;
    border-radius: 5px;
    /* height: 40px; */
}
.search-input {
    padding: 10px 35px 10px 10px; /* Adjust padding to accommodate the icon */
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="25px" height="25px"><path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"/></svg>')
  no-repeat right 10px center;/* Position and size the search icon */
}
  
  
  
  
  
  
.paginationDiv .pagination {
    justify-content: end;
    padding: 6px 14px;
}


.model-form .form-control {
    border: none;
    border-bottom: 1px solid #3d4A9E;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
}

.model-form .btn-primary,
.model-form .MuiButton-contained,
.model-form .btn-primary:hover {
    background:#3E4A9E;
    border-color:#3E4A9E;
}

.model-form label {
    color: #000;
}

.userBlockHorizontalDropdown {
    background-color: #b4d3dd;
    color:black
}

.financialChartPrice,
.financialChartPrice>div {
    min-height: 300px !important;
}

.rotate {

    /* Safari */
    -webkit-transform: rotate(-90deg);

    /* Firefox */
    -moz-transform: rotate(-90deg);

    /* IE */
    -ms-transform: rotate(-90deg);

    /* Opera */
    -o-transform: rotate(-90deg);

    float: left;

}

.rangeslider {
    margin: 0 !important;
}

.rangeslider-horizontal {
    height: 6px !important;
    width: 300px !important;
}

.rangeslider-horizontal .rangeslider__handle {
    width: 17px !important;
    height: 17px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
    width: 10px !important;
    height: 10px !important;
    top: 2px !important;
    left: 2px !important;
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #0b3d45cf !important;
}


/* 
        background-color: #0b3d45cf !important;
     */


/* input fields css */
.input-lg {
    height: 45px !important;
}

.input-md {
    height: 45px !important;
}

.PhoneInputInput {
    border: none;
    font-size: 1rem;
    padding-left: 10px;
}

.user-form-text-area {
    line-height: 30px;
}



/* Css for New version Login Page */
.loginContainer {
    max-width: 100wh;
    margin: 0 auto;
    padding: 90px 92px 116px 114px;
    /* background-image: linear-gradient(to bottom, #b5e2fa, #c4e1f1, #c4e1f1, #d9d9d9, #fff); */
    /* background-image: linear-gradient(to bottom, #b5e2fa, #cee7fe, #e4eeff, #f4f6ff, #ffffff); */

      background: linear-gradient(180deg, #B8DAE3 -50.28%, #FFFFFF 103.89%);

    height: 100vh;
     /* height:100vh;
    box-sizing: border-box; */
/* Hide both horizontal and vertical scrollbars */
overflow: hidden;

/* Enable scrolling */
overflow: auto;

/* Hide scrollbar track and thumb */

}

.pr-scroll{
    /* Hide both horizontal and vertical scrollbars */
overflow: hidden;

/* Enable scrolling */
overflow: auto;

/* Hide scrollbar track and thumb */

}

::-webkit-scrollbar {
    width: 0.1em;
    height: 0.1em;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

.loginInfoWrapper img {
    object-fit: contain;
}

.loginInfoWrapper {
    padding-top: 20px;
}

.loginInfoWrapper .loginMeta {
    font-family: 'IBM Plex Sans', sans-serif;
    color: #000000;
    font-size: 44px;
    line-height: 65px;
    font-weight: 800;
    margin-bottom: 0;
    padding: 80px 0;
}

.loginInfoWrapper .metaDetails {
    font-size: 26px;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #000000;
    margin-bottom: 0;
    font-weight: 800;
    border-bottom: 2px dashed #ABABAB;
    padding-bottom: 5px;
    width: max-content;
}

.loginForm {
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 80px 70px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    max-width: 700px;
}

.loginForm-title {
    color: #3E4A9E;
    font-weight: 600;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 44px;
    line-height: 65px;
    margin-bottom: 49px;
}

.loginForm .has-wrapper .has-input {
    background-color: #F2F2F2;
    border: none;
    border-bottom: 1px solid #000;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
}

.loginForm .form-control {
    color: #000000;
    font-size: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    background-color: #F2F2F2;

}

.loginForm .form-group {
    margin-bottom: 0;
}

.loginForm .forget-psw {
    font-family: 'IBM Plex Sans', sans-serif;
    text-align: right;
    color: #3E4A9E;
    font-size: 18px;
    line-height: 32px;
    cursor: pointer;
    padding-top: 10px;
    width: max-content;
    margin-left: auto;
}

.input-margin {
    margin-bottom: 70px !important;
}

.loginForm .LogInBtn {
    background-color: #393185 !important;
    color: #fff;
    border-radius: 35px;
    border: none;
    box-shadow: none;
    padding: 13px 20px;
    max-width: 450px;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 24px;
    cursor: pointer;
}

.loginForm .signUpTitle {
    font-size: 20px;
    color: #000;
    text-align: center;
    font-family: 'IBM Plex Sans', sans-serif;
    line-height: 32px;
    opacity: 0.7;
    cursor: pointer;
    text-decoration: underline;
    padding-top: 20px;
    margin-bottom: 0;


}

.loginForm .checkBoxRow {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px 0 56px;
}

.loginForm .checkBoxRow p {
    color: #000000;
    font-size: 24px;
    margin-bottom: 0;
    font-family: 'IBM Plex Sans', sans-serif;

}

.checkBoxRow .form-check-input {
    position: static;
    margin-top: 0;
    margin-left: 0;
    height: 33px;
    width: 33px;
}

.loginForm .line {
    border-top: 8px solid #3E4A9E;
    border-radius: 8px;
    position: absolute;
    top: 0;
    /* left: 0; */
    width: 115px;
}

.loginContainer .row-gapper {
    gap: 165px;
}

.DroneBox{
/* position: relative;
margin-top: 50px; */
}
.dronIcon{
    /* position: absolute;
    top: -120px;
    left: 10%; */
    width:80%;
    height:400px;
}

/* CSS for Select Module Page  */
.moduleBackground {
    /* background-image: url("../img/dashboard.jpg"); */
    /* background-size: 100% 100%; */
    /* max-height: 100vh;
    max-width: 100vw; 
    background-color: rgba(204, 227, 233, 1); */
    background:rgb(189,217,226) !important;
    max-width: 100vw; 
}

.dashboard-title {
    font-size: 30px;
    line-height: 70px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    color: #000;
    max-width: 750px;
    width: 100%;
    
}

.mobuleSection img {
    object-fit: contain;
}

.mobuleSection {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 58px 35px;
    gap: 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

}

.mobuleSection h4 {
    margin-bottom: 0;
    color: #04050B;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 36px;

}

.moduleWRapper {
    display: flex;
    flex-direction: column;
    gap: 55px;
}

.moduleLink {
    background-color: #3E4A9E;
    border-radius: 50%;
    box-shadow: 4px 4px 10px rgb(0 0 0 / 25%);
    width: 94px;
    height: 94px;
    position: absolute;
    right: -34px;
}
.moduleLink:hover {
  background-color: green;
}

.moduleLink a {
    position: absolute;
    right: 23px;
    z-index: 1;
    top: 28px;
    color: #fff !important;
    cursor: pointer;
}

.moduleLink a .MuiSvgIcon-root, .moduleLink a .css-vubbuv {
    width: 40px;
    height: 40px;
}

/* CSS for doughnut canvas */
.Doughnut-in-Project-cart canvas {
    width: 110px !important;
    height: 110px !important;
}


input:-webkit-autofill {
    background-color: #F2F2F2 !important;
    -webkit-background-clip: text !important;
}



/**************** Table stylingsssss *************/
/* table with 4 columns */
table.tableFourCols tr {
    width: 100% !important;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EBEDF2;
}

table.tableFourCols tr:last-child{
    border-bottom: 1px solid #EBEDF2;
}

table.tableFourCols tr th.sr,
table.tableFourCols tr td.sr{
    /* background-color: orange; */
    width: 60px;
}

table.tableFourCols tr td{
    width: 280px;
    /* background-color: red; */
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none !important;
}

table.tableFourCols tr th{
    width: 280px;
    /* background-color: pink; */
    text-align: start;
    border: none !important;
}

/* table with 5 columns */
table.tableFiveCols tr {
    width: 100% !important;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EBEDF2;
}

table.tableFiveCols tr:last-child {
    border-bottom: 1px solid #EBEDF2;
}

table.tableFiveCols tr th.sr,
table.tableFiveCols tr td.sr{
    /* background-color: orange; */
    width: 60px;
}

table.tableFiveCols tr td{
    width: 200px;
    /* background-color: red; */
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none !important;
}

table.tableFiveCols tr th{
    width: 200px;
    /* background-color: pink; */
    text-align: start;
    border: none !important;
}


/* table with 6 columns */
table.tableSixCols tr {
    width: 100% !important;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EBEDF2;
}

table.tableSixCols tr:last-child {
    border-bottom: 1px solid #EBEDF2;
}

table.tableSixCols tr th.sr,
table.tableSixCols tr td.sr {
    /* background-color: orange; */
    width: 60px;
}

table.tableSixCols tr td{
    width: 140px;
    /* background-color: red; */
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none !important;
}

table.tableSixCols tr th{
    width: 140px;
    /* background-color: pink; */
    text-align: start;
    border: none !important;
}


/* tablw with 7 columns */


/* table with 8 cols */
table.tableEightCols tr {
    width: 100% !important;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;

    border-top: 1px solid #EBEDF2;
}

table.tableEightCols tr:last-child {
    border-bottom: 1px solid #EBEDF2;
}

table.tableEightCols tr th.sr,
table.tableEightCols tr td.sr {
    /* background-color: orange; */
    width: 60px;
}

table.tableEightCols tr td{
    width: 110px;
    /* background-color: red; */
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none !important;
}

table.tableEightCols tr th{
    width: 110px;
    /* background-color: pink; */
    text-align: start;
    border: none !important;
}

@media (min-width:500px) and (max-width: 1023px){
    .profile-block-wrap {
        /* left: 5px; */
    }
    .dronIcon{
        
        width:100%;
        height:400px;
    
        }
}

@media (min-width:1000px) and (max-width: 1199px) {

    .loginInfoWrapper {
        padding-top: 36px;
        
    }
    .dronIcon{
        
        width: 100%;
        height: 313px;
    
        }


    .loginInfoWrapper .metaDetails {
        font-size: 16px;
    }

    .loginInfoWrapper .loginMeta {
        font-size: 24px;
        line-height: 25px;
        padding: 85px 0;
    }

    .loginContainer {
        padding: 16px 60px 16px 60px;
        height: -webkit-fill-available;
        
    }

    .loginForm {
        padding: 40px 24px;

    }
    
    .widthclass{
        width: 50%;
    }


    .loginForm .forget-psw {
        font-size: 16px;
        line-height: 24px;
        padding-top: 8px;
        margin-bottom: 0;
    }


    .loginForm .form-control {
        font-size: 16px;
    }

    .loginForm-title {
        font-size: 23px;
    line-height: 36px;
    margin-bottom: 24px;
    max-width: 100%;
    }

    .input-margin {
        margin-bottom: 28px !important;
    }

    .checkBoxRow .form-check-input {
        height: 22px;
        width: 22px;
    }

    .input-lg {
        height: 42px !important;
    }

    .loginForm .checkBoxRow p {
        font-size: 16px;
    }

    .loginForm .checkBoxRow {
        padding: 16px 0 20px;
        gap: 10px;
    }

    .loginForm .LogInBtn {
        padding: 10px 12px;
        font-size: 18px;
        width: 92%;

    }

    .loginInfoWrapper img {
        width: 200px;
        height: 100px;
    }

    .loginForm .signUpTitle {
        font-size: 16px;
        padding-top: 12px;
        padding-bottom: 1px;
    }


    .loginContainer .row-gapper {
        gap: 0px;
    }

    .mobuleSection {
        padding: 16px 30px;
        gap: 24px;
    }


    .mobuleSection h4 {
        font-size: 24px;
    }


    .moduleLink {
        width: 65px;
        height: 65px;
        right: -30px;
    }

    .moduleLink a .MuiSvgIcon-root, .moduleLink a .css-vubbuv  {
        width: 28px;
        height: 28px;
    }

    .moduleLink a {
        right: 15px;
        top: 16px;

    }

    .dashboard-title {
        font-size: 28px;
        line-height: 40px;
      
    }

    .mobuleSection img {
        width: 170px;
        height: 75px;
    }

    .profile-block-wrap {
        /* left: 5px; */
    } 

    /********** Table Media Query **********/
    table.tableSixCols tr th.sr,
    table.tableSixCols tr td.sr {
        /* background-color: orange; */
        width: 60px;
    }

    table.tableSixCols tr td{
        width: 160px;
    }

    table.tableSixCols tr th{
        width: 160px;
    }
}


@media (min-width:1200px) and (max-width: 1359px) {

    .loginInfoWrapper {
        padding-top: 36px;
    }
    .DroneBox{
margin-top: 50px;
    }
    .dronIcon{
        
        width:100%;
        height:400px;
    
        }
    .loginInfoWrapper img {
        width: 200px;
        height: 100px;
    }

    .loginInfoWrapper .metaDetails {
        font-size: 18px;
    }

    .loginInfoWrapper .loginMeta {
        font-size: 30px;
        line-height: 50px;
        padding: 100px 0;
    }

    .loginContainer {
        padding: 70px 72px 70px 80px;
        height: -webkit-fill-available;
    }

    .loginForm {
        padding: 48px 55px;
    }
    

    .loginForm-title {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 30px;
    }

    .input-margin {
        margin-bottom: 50px !important;
    }

    .checkBoxRow .form-check-input {
        height: 28px;
        width: 28px;
    }

    .loginForm .checkBoxRow p {
        font-size: 20px;
    }

    .loginForm .checkBoxRow {
        padding: 16px 0 32px;
    }

    .loginForm .LogInBtn {
        padding: 10px 20px;
        font-size: 22px;
    }

    .loginForm .signUpTitle {
        font-size: 18px;
        padding-top: 16px;
    }


    .loginContainer .row-gapper {
        gap: 120px;  grid-gap: 105px;
        
        gap: 26px;
    }

    .mobuleSection {
        padding: 36px 25px;
        gap: 32px;
    }


    .mobuleSection h4 {
        font-size: 30px;
    }


    .moduleLink {
        width: 75px;
        height: 75px;
        right: -30px;
    }

    .moduleLink a .MuiSvgIcon-root, .moduleLink a .css-vubbuv  {
        width: 32px;
        height: 32px;
    }

    .moduleLink a {
        right: 20px;
        top: 25px;

    }

    .dashboard-title {
        font-size: 32px;
        line-height: 46px;
       
    }

    .mobuleSection img {
        width: 170px;
        height: 75px;
    }

    .profile-block-wrap {
        /* left: 10px; */
    }

    /********** Table Media Query **********/
    table.tableFourCols tr th.sr,
    table.tableFourCols tr td.sr{
        width: 60px;
    }

    table.tableFourCols tr td{
        width: 265px;
    }

    table.tableFourCols tr th{
        width: 265px;

    }

    table.tableSixCols tr th.sr,
    table.tableSixCols tr td.sr {
        /* background-color: orange; */
        width: 60px;
    }

    table.tableSixCols tr td{
        width: 165px;
        transition: 0.3s;
    }

    table.tableSixCols tr th{
        width: 165px;
        transition: 0.3s;
    }
}

@media (min-width:1360px) and (max-width: 1560px) {
    .loginInfoWrapper {
        padding-top: 36px;
    }
    .DroneBox{
margin-top: 50px;
    }
    .dronIcon{
        
        width:100%;
        height:400px;
    
        }
    .loginInfoWrapper img {
        width: 200px;
        height: 100px;
    }

    .loginInfoWrapper .metaDetails {
        font-size: 18px;
    }

    .loginInfoWrapper .loginMeta {
        font-size: 30px;
        line-height: 50px;
        padding: 100px 0;
    }

    .loginContainer {
        padding: 70px 72px 70px 80px;
        height: -webkit-fill-available;
    }

    .loginForm {
        padding: 48px 55px;
    }
    

    .loginForm-title {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 30px;
    }

    .input-margin {
        margin-bottom: 50px !important;
    }

    .checkBoxRow .form-check-input {
        height: 28px;
        width: 28px;
    }

    .loginForm .checkBoxRow p {
        font-size: 20px;
    }

    .loginForm .checkBoxRow {
        padding: 16px 0 32px;
    }

    .loginForm .LogInBtn {
        padding: 10px 20px;
        font-size: 22px;
    }

    .loginForm .signUpTitle {
        font-size: 18px;
        padding-top: 16px;
    }


    .loginContainer .row-gapper {
        gap: 120px;  grid-gap: 105px;
        
        gap: 26px;
    }


    .mobuleSection {
         padding: 45px 28px;
        gap: 32px;
    }


    .mobuleSection h4 {
        font-size: 34px;
    }


    .moduleLink {
        width: 75px;
        height: 75px;
        right: -28px;
    }

    .moduleLink a .MuiSvgIcon-root , .moduleLink a .css-vubbuv {
        width: 30px;
        height: 30px;
    }

    .moduleLink a {
        right: 20px;
        top: 25px;

    }

    .dashboard-title {
        font-size: 30px;
        line-height: 46px;
        
    }

    .mobuleSection img {
        width: 110px !important;
        height: 60px !important;
    }

    .profile-block-wrap {
        /* left: 15px; */
    }


    /********* Table Media Query *********/
    table.tableFourCols tr th.sr,
    table.tableFourCols tr td.sr{
        width: 60px;
    }

    table.tableFourCols tr td{
        width: 310px;
    }

    table.tableFourCols tr th{
        width: 310px;

    }

    table.tableFiveCols tr th.sr,
    table.tableFiveCols tr td.sr{
        width: 60px;
    }

    table.tableFiveCols tr td{
        width: 230px;
    }

    table.tableFiveCols tr th{
        width: 230px;
    }

    table.tableSixCols tr th.sr,
    table.tableSixCols tr td.sr {
        /* background-color: orange; */
        width: 60px;
    }

    table.tableSixCols tr td{
        width: 180px;
    }

    table.tableSixCols tr th{
        width: 180px;
    }

    table.tableEightCols tr th.sr,
    table.tableEightCols tr td.sr {
        width: 60px;
        transition: all 0.3s;
    }

    table.tableEightCols tr td{
        width: 130px;
        transition: all 0.3s;
    }

    table.tableEightCols tr th{
        width: 130px;
        transition: all 0.3s;
    }
}


@media (min-width:1440px) {
    table.tableFourCols tr th.sr,
    table.tableFourCols tr td.sr{
        min-width: 80px;
    }

    table.tableFourCols tr td{
        min-width: 30%;
    }

    table.tableFourCols tr th{
        min-width: 30%;

    }

    table.tableFiveCols tr th.sr,
    table.tableFiveCols tr td.sr{
        width: 80px;
    }

    table.tableFiveCols tr td{
        width: 22%;
    }

    table.tableFiveCols tr th{
        width: 22%;
    }

    table.tableSixCols tr th.sr,
    table.tableSixCols tr td.sr {
        /* background-color: orange; */
        width: 80px;
    }

    table.tableSixCols tr td{
        width: 18%;
    }

    table.tableSixCols tr th{
        width: 18%;
    }

    table.tableEightCols tr th.sr,
    table.tableEightCols tr td.sr {
        width: 80px;
        transition: all 0.3s;
    }

    table.tableEightCols tr td{
        width: 13%;
        transition: all 0.3s;
    }

    table.tableEightCols tr th{
        width: 13%;
        transition: all 0.3s;
    }
}






@media (min-width:2500px) and (max-width: 5000px) {
.loginContainer{
     padding-left: 20%;
     padding-right: 20%;
}
}

/* 
@media (min-width:3340px) and (max-width: 4040px) {
    .dronIcon{
        top: -90px;
        left: 10%;
        width:700px;
        height:500px;

    }
    .DroneBox{
        margin-top: 80px !important;
        padding-left: 100px;
        padding-right: 100px;
    }

    .loginContainer .row-gapper {
        gap: 195px;
    }

    .loginInfoWrapper .metaDetails {
        font-size: 22px;
    }

    .loginInfoWrapper .loginMeta {
        font-size: 38px;
        line-height: 54px;
        padding: 105px 0;
    }

    .loginContainer {
        padding: 60px 70px 36px 70px;
        height: -webkit-fill-available;
    }

    .loginForm {
        padding: 54px 58px;

    }

    .loginForm-title {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 32px;
    }

    .input-margin {
        margin-bottom: 34px !important;
    }

    .checkBoxRow .form-check-input {
        height: 28px;
        width: 28px;
    }

    .loginForm .checkBoxRow p {
        font-size: 20px;
    }

    .loginForm .checkBoxRow {
        padding: 16px 0 28px;
    }

    .loginForm .LogInBtn {
        padding: 10px 20px;
        font-size: 22px;
    }

    .loginForm .signUpTitle {
        font-size: 18px;
        padding-top: 16px;
    }

    .mobuleSection {
         padding: 100px 88px;
        gap: 32px;
    }


    .mobuleSection h4 {
        font-size: 74px;
    }


    .moduleLink {
        width: 215px;
        height: 215px;
        right: -48px;
    }

    .moduleLink a .MuiSvgIcon-root , .moduleLink a .css-vubbuv {
        width: 70px;
        height: 70px;
    }

    .moduleLink a {
        right: 60px;
        top: 5px;

    }

    .dashboard-title {
        font-size: 70px;
        line-height: 96px;
        padding-top: 70px;
        max-width: 100%;
    }

    .mobuleSection img {
        width: 310px !important;
        height: 260px !important;
    }

    .profile-block-wrap {
        left: 15px;
    }


   
} */
