
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: transparent !important;
    border-radius: 10px !important;


}
.css-uhb5lp{
    margin: 0px !important;
    box-shadow: none !important;
}
.upp-Modal{
   /* box-sizing: border-box; */
   
   max-width:750px;
   
}

/* Style the "Done" button */
.uppy-DashboardContent-back {
    background-color: red;
    color: white;
    font-weight: 800 !important;
   
  }
  .uppy-DashboardContent-back:hover {
    background-color: rgb(222, 17, 17);
    color: white;
    font-weight: 800 !important;
   
  }
  /* Style the "Cancel" button
  .uppy-DashboardContent-cancel {
    background-color: yellow;
    color: white;
    font-weight: 700 !important;
  } */
  
  /* Style the "Add files" button */
  .uppy-DashboardContent-addMore {
    background-color:#3d4A9E;
    color: white;
    font-weight: 700 !important;
  }
  .uppy-DashboardContent-addMore:hover{
    color: white !important;
    background-color: #5662ac !important;
  }
  
  /* Set the background color of the dashboard */
  .uppy-Dashboard-inner {
    background-color: #C4C8E1;
    
  }
  .uppy-StatusBar-actionBtn--upload{
    background-color: #3d4A9E !important;
    font-weight: 700 !important;
  }
  .uppy-StatusBar-actionBtn--upload:hover{
    background-color: #5662ac !important;
    font-weight: 700 !important;
  }
  .uppy-StatusBar-actionBtn--done{
    background-color: #5964ab !important;
    color: white;
    font-weight: 700 !important;
  }
  .uppy-StatusBar-actionBtn--done:hover{
    color: white !important;
    background-color: #5662ac !important;
  }